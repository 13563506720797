<template>
  <nav role="navigation" class="flex items-center justify-between" v-if="pagination.lastPage > 1">
    <div class="flex justify-between flex-1 sm:hidden">
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        {{ `${trans('PAGINATION:Showing')} ${pagination.from} ${trans('PAGINATION:To')} ${pagination.to} ${trans('PAGINATION:Of')} ${pagination.total}` }}
      </div>
      <div>
        <t-pagination
            variant="posts"
            :total-items="pagination.total"
            :per-page="pagination.perPage"
            :limit="5"
            v-model="page"
            @change="$emit('next', page)"
            :disabled="disabled"
        ></t-pagination>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: Object,
    disabled: Boolean,
    currentPage: Number,
  },
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    set() {
      this.page = this.currentPage
    },
    get() {
      return this.currentPage
    }
  }
}
</script>
