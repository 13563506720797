<template>
  <div class="overflow-x-auto">
    <table class="w-full text-xs text-left table-auto">
      <thead class="text-xs text-black uppercase bg-grayedLight">
      <tr>
        <th
          v-for="header in headers" :style="'min-width:' + header.width"
          scope="col"
          class="px-2 py-1"
        >
          {{ trans(header.name) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="border-b" v-for="(value, index) in data.length" :key="index" :class="{
        'bg-badgeErrorPale':  colorRowAttr ? data[value-1][[colorRowAttr]] !== false : false
      }">
        <td
          v-for="row in headers"
          class="py-1 px-2"
          :class="{'flex flex-row space-x-2': row.value === 'actions'}"
        >
          <template>
            <slot :name="[row.value]" v-bind:item="data[index]">
            </slot>

            <template v-if="row.hasOwnProperty('isImage')">
              <Picture classes="w-32 object-cover" :src="data[index][row.value]" />
            </template>
            <template v-else-if="!$scopedSlots[row.value]">
              {{ data[index][row.value] }}
            </template>

          </template>
          <template v-if="row.value === 'actions'">
            <div class="flex flex-row gap-3" v-if="!customActions">
              <Button
                  variant="link"
                  :text="trans('BUTTON:Edit')"
                  @clicked="$emit('edit', data[index].id)"
              />
              <Button
                  class="text-redAccent"
                  variant="link"
                  :text="trans('BUTTON:Delete')"
                  @clicked="showDeleteModal(true, data[index].id)"
              />
            </div>
          </template>
        </td>
      </tr>
      </tbody>
    </table>
	  <Modal
			  :show-modal="isShowDeleteModal"
			  :header="trans('MODAL_TITLE:Attention')"
			  @onConfirm="deleteAction"
			  @clicked="showDeleteModal(false)"
			  :button-confirm-text="trans('BUTTON:Delete')"
	  >
		  <template v-slot:content>
			  {{ trans('MODAL_DESCRIPTION:DeleteRecord') }}
		  </template>
	  </Modal>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import Modal from "@/utils/modal/Modal.vue";
export default {
  name: "DataTable",
  components: {Modal, Button},
	data() {
		return {
			isShowDeleteModal: false,
			dataToDelete: null,
		}
	},
  props: {
    headers: Array|Object,
    data: Array|Object,
    customActions: {
      type: Boolean,
      default: false
    },
    colorRowAttr: {
      type: String,
    }
  },
	methods: {
		showDeleteModal(val, data = null) {
			this.isShowDeleteModal = val;

			this.dataToDelete = data;
		},
		deleteAction() {
			this.$emit('delete', this.dataToDelete)
			this.showDeleteModal(false);
		}
	}
}
</script>
