<template>
  <ValidationObserver ref="blockReasonForm" v-slot="{ invalid }">
    <div class="flex flex-col gap-y-3">
      <FieldWrapper>
        <VueTrix
		        class="block-reason-form"
            field="blockedReason"
            :placeholder="trans('LABEL:BanReason')"
            rules="required"
            v-model="blockReason"
        />
      </FieldWrapper>
    </div>
    <div class="flex flex-row justify-end mt-4">
      <Button
          type="submit"
          @clicked="blockUserAction"
          :disabled="invalid"
          :loading="userBanning"
          :text="trans('BUTTON:BanUser')"
      />
    </div>
  </ValidationObserver>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import TextareaField from "@/utils/crud/components/field-types/Textarea";
import {mapActions, mapGetters} from "vuex";


export default {
  name: "BlockReasonForm",
  components: {TextareaField, FieldWrapper, Button},
  props: {
    user: Object,
  },
  data() {
    return {
      blockReason: null,
    }
  },
  computed: {
    ...mapGetters('adminUsers', ['userBanned', 'userBanning'])
  },
  methods: {
    ...mapActions('adminUsers', ['banUserRequest']),
    blockUserAction() {
      this.banUserRequest({
        banned: this.user.bannedAt === null,
        userId: this.user.id,
        banReason: this.blockReason
      })
    }
  }
}
</script>

<style lang="scss">
.block-reason-form {
	.trix-content {
		height: 290px;
	}
}
</style>